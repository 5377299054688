@import "~@sothebys/sterling/tokens/colors.css";

*,
*::before,
*::after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: benton-sans, sans-serif;
  font-weight: 500;
  color: var(--colors-primary-grey90);
}

input,
select {
  font-size: 100%;
}

[data-consent-manager-dialog] div {
  z-index: 1122;
}

#consent-manager div div {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  height: 48px;
  width: 684px;
  padding-top: 24px;
  padding-right: 24px;
  padding-left: 24px;
  z-index: 1000;
}

#consent-manager div p {
  margin-right: 8px;
}

/*Tablet*/
@media screen and (max-width: 980px) {
  #consent-manager div div {
    width: 550px;
    height: 48px;
  }
}

/*Mobile*/
@media screen and (max-width: 768px) {
  #consent-manager div div {
    width: 350px;
    height: 80px;
  }
}

#consent-manager div p {
  display: inline;
}

#consent-manager div button[title="Close"] {
  font-size: 20px;
  position: absolute;
  top: 20px;
  text-decoration: none;
  border-bottom: 0px;
  z-index: 1001;
}

#consent-manager div button,
#consent-manager div a {
  border-bottom: 1px solid;
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.5px;
  text-decoration: none;
  color: var(--colors-primary-white);
  font-family: "benton-sans";
}
